<template>
    <div class="p-2">

        <div class='d-flex'>
            <div class="mx-auto">
                <div class="d-flex mt-1">
                    <img :src="input_data.sha_picture ? input_data.sha_picture : default_img" class="rounded-circle m-auto"
                        style="height:100px;width:100px" />
                </div>

                <div class="text-center my-1">
                    <h3 class="text-white h4 mb-0">{{ input_data.sha_name }}</h3>
                    <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{ input_data.sha_species }}</h3>
                    <h3 class="text-white h4 mb-0">{{ input_data.sha_age }} Months, {{ input_data.sha_gender }}</h3>
                    <p class="mt-1 text-white">
                        {{ input_data.sha_bio }}
                    </p>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-center" style="gap:10px" v-if="user.user_id != shelter.user_id">
            <!-- <div class="btn py-1" style="
                border-radius: 15px;
                border: 2px solid #FFAD32;
                background: #005071;
                color: white;
            ">
                <i class="bx bx-share-alt" style="font-size: 16px;cursor: pointer;color: #FFAD32;"></i>
            </div> -->
            <div class="btn px-1" style="
                border-radius: 15px;
                border: 2px solid #FFAD32;
                background: #005071;
                color: white;
            " @click="reqAdoption()" v-if="!applied_adoption.includes(isShown.sha_id)">
                Request Adoption
            </div>
            <div class="btn px-1" style="
                border-radius: 15px;
                border: 2px solid #FFAD32;
                background: #005071;
                color: white;
            " v-else>
                Already Requested
            </div>
            <div class="btn px-1" style="
                border-radius: 15px;
                border: 2px solid #FFAD32;
                background: #005071;
                color: white;
            " @click="openModal()" v-if="!online_pets.includes(isShown.sha_id)">
                Be Online Parent
            </div>
            <div class="btn px-1" style="
                border-radius: 15px;
                border: 2px solid #FFAD32;
                background: #005071;
                color: white;
            " v-else>
                You Are The Online Parent
            </div>
            <!-- <div class="btn py-1" style="
                border-radius: 15px;
                border: 2px solid #FFAD32;
                background: #005071;
                color: white;
            ">
                <i class="bx bxs-message-dots" style="font-size: 16px;cursor: pointer;color: #FFAD32;"></i>
            </div> -->
        </div>

        <h3 class="text-white font-weight-bolder text-center mt-3 h3">Online Parents</h3>

        <div class="text-center text-white h4" v-if="donators.length < 1">
            No Data Available
        </div>

        <div class="p-1 mt-1" style="border-radius: 10px;border:2px solid #FFAD32" v-for="item in donators">
            <div class="d-flex align-items-center" style="gap:10px 20px">
                <div>
                    <img :src="item.user.user_picture ? item.user.user_picture : default_img" class="rounded-circle"
                        style="height:50px;width:50px;border:2px solid white" />
                </div>
                <div>
                    <h3 class="text-white h4 mb-0">{{ item.user.user_name }}</h3>
                    <span class="text-white">Donating at {{ moment(item.created_at).format('dddd, DD MMMM YYYY') }}</span>
                </div>
            </div>
            <div class="d-flex align-items-center mt-1" style="gap:10px 20px">
                <div class="h4 text-white mb-0">Amount ${{ item.nominal }} per month</div>
            </div> 
        </div>
 
        <!-- modal -->
        <b-modal title="Online Adoption" centered content-class="blue-modal" header-class="" hide-footer hide-header v-model="isModalOpen">
            
            <div class="d-flex mt-1">
                <img :src="isShown.sha_picture ? isShown.sha_picture : default_img"
                    class="rounded-circle m-auto" style="height:100px;width:100px" />
            </div>

            <div class="text-center my-1">
                <h3 class="text-white h4 mb-0">{{isShown.sha_name}}</h3>
                <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{isShown.sha_species}}</h3>
                <h3 class="text-white h4 mb-0">{{isShown.sha_age}} Months, {{isShown.sha_gender}}</h3>
                <p class="mt-1">
                    {{ isShown.sha_bio }}
                </p>
            </div>

            <div class="mt-3 h4 text-white font-weight-bolder">Would You Like To Be My Online Parent?</div>
            <h3 class="text-white h5 mb-0">How Much Would You Like To Provide Me Per Month?</h3>
            <input type="number" class="form-control mb-2 text-center" style="background-color: transparent;color: white;
                border:unset;border-bottom: 1px solid white !important;border-radius: unset;" v-model="donating_amount" />

            <div class="d-flex flex-wrap align-items-center justify-content-between mb-1" style="gap:10px">
                <div class="btn" style="
                    border-radius: 15px;
                    border: 2px solid #FFAD32;
                    background: #005071;
                    color: white;
                ">
                    <i class="bx bx-share-alt" style="font-size: 16px;cursor: pointer;color: #FFAD32;"></i>
                </div>
                <div class="btn px-3" style="
                    border-radius: 15px;
                    border: 2px solid #FFAD32;
                    background: #005071;
                    color: white;
                " @click="donateNow()">
                    Donate
                </div>
            </div>
            
        </b-modal>
        <!-- end modal -->

    </div>
</template>

<script>
import {BModal} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import moment from 'moment'

import Cookies from 'universal-cookie'


import store from '@/store'
let cookies = new Cookies()

export default {
    components: {
        BModal,
        Swiper, SwiperSlide,
    }, 
    methods: {
        reqAdoption(){
            this.$router.push('/shelter/adopt/form/' + this.input_data?.shelter_id + '/' + this.id)
        },
        openModal(item) {
            this.isShown = item
            this.donating_amount = 0
            this.isModalOpen = true
        },
        donateNow() {
            store.dispatch('shelter/ApplyOnlineParent', {
                user_id: cookies.get('token'),
                sha_id: this.id,
                shelter_id: this.input_data.shelter_id,
                nominal: this.donating_amount,
            }).then(() => {
                store.dispatch('auth/GetUserData')
                this.$router.push({ path: '/shelter/adopt/invoice/'+this.input_data.shelter_id, query: { 
                    nominal: this.donating_amount,
                    item: this.isShown
                }})
            })
        }
    },
    computed: {
        donators(){
            return this.input_data?.online_parents
        },
        isShown(){
            return this.input_data
        },
        user() {
            return store.state.auth.USER
        },
        shelter() {
            return store.state.shelter.SHELTER
        },
        online_pets(){
            return this.user?.online_pets?.map(item => (item.sha_id)) || []
        },
        applied_adoption(){
            return this.user?.applied_adoption?.map(item => (item.sha_id)) || []
        },
    },
    async mounted(){
        this.input_data = await store.dispatch('shelter/GetAdoption', this.id)  
    },
    data(){
        return {
            donating_amount: 0,
            moment,
            input_data: {},
            id: this.$route.params.sha_id,
            isModalOpen: false,  
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'

        }
    }, 
}

</script>

<style>
.blue-modal {
    background-color: #1F9BCE;
    color: white;
    border-radius: 20px;
}

</style>